import {
	DisplayFieldsResponse,
	MgoObjectChange,
	MgoObjectChangeCreated,
	MgoObjectChangeDeleted,
	MgoObjectChangeMutated,
	MgoObjectChangePublished,
	MgoObjectChangeTransferred,
	MgoObjectChangeWrapped,
} from '@mgonetwork/mango.js/client';

import { groupByOwner } from './groupByOwner';
import { MgoObjectChangeTypes } from './types';

export type WithDisplayFields<T> = T & { display?: DisplayFieldsResponse };
export type MgoObjectChangeWithDisplay = WithDisplayFields<MgoObjectChange>;

export type ObjectChanges = {
	changesWithDisplay: MgoObjectChangeWithDisplay[];
	changes: MgoObjectChange[];
	ownerType: string;
};
export type ObjectChangesByOwner = Record<string, ObjectChanges>;

export type ObjectChangeSummary = {
	[K in MgoObjectChangeTypes]: ObjectChangesByOwner;
};

export const getObjectChangeSummary = (objectChanges: MgoObjectChangeWithDisplay[]) => {
	if (!objectChanges) return null;

	const mutated = objectChanges.filter(
		(change) => change.type === 'mutated',
	) as MgoObjectChangeMutated[];

	const created = objectChanges.filter(
		(change) => change.type === 'created',
	) as MgoObjectChangeCreated[];

	const transferred = objectChanges.filter(
		(change) => change.type === 'transferred',
	) as MgoObjectChangeTransferred[];

	const published = objectChanges.filter(
		(change) => change.type === 'published',
	) as MgoObjectChangePublished[];

	const wrapped = objectChanges.filter(
		(change) => change.type === 'wrapped',
	) as MgoObjectChangeWrapped[];

	const deleted = objectChanges.filter(
		(change) => change.type === 'deleted',
	) as MgoObjectChangeDeleted[];

	return {
		transferred: groupByOwner(transferred),
		created: groupByOwner(created),
		mutated: groupByOwner(mutated),
		published: groupByOwner(published),
		wrapped: groupByOwner(wrapped),
		deleted: groupByOwner(deleted),
	};
};
