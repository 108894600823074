import type { Infer } from 'superstruct';
import {
	array,
	assign,
	boolean,
	is,
	literal,
	nullable,
	number,
	object,
	optional,
	string,
	tuple,
	union,
} from 'superstruct';

import { MgoJsonValue, ObjectOwner } from './common.js';
import { MgoEvent } from './events.js';
import { MgoGasData, MgoMovePackage, MgoObjectRef } from './objects.js';

/** @deprecated Use `string` instead. */
export const EpochId = string();

export const MgoChangeEpoch = object({
	epoch: string(),
	storage_charge: string(),
	computation_charge: string(),
	storage_rebate: string(),
	epoch_start_timestamp_ms: optional(string()),
});
export type MgoChangeEpoch = Infer<typeof MgoChangeEpoch>;

export const MgoConsensusCommitPrologue = object({
	epoch: string(),
	round: string(),
	commit_timestamp_ms: string(),
});
export type MgoConsensusCommitPrologue = Infer<typeof MgoConsensusCommitPrologue>;

export const Genesis = object({
	objects: array(string()),
});
export type Genesis = Infer<typeof Genesis>;

export const MgoArgument = union([
	literal('GasCoin'),
	object({ Input: number() }),
	object({ Result: number() }),
	object({ NestedResult: tuple([number(), number()]) }),
]);
export type MgoArgument = Infer<typeof MgoArgument>;

export const MoveCallMgoTransaction = object({
	arguments: optional(array(MgoArgument)),
	type_arguments: optional(array(string())),
	package: string(),
	module: string(),
	function: string(),
});
export type MoveCallMgoTransaction = Infer<typeof MoveCallMgoTransaction>;

export const MgoTransaction = union([
	object({ MoveCall: MoveCallMgoTransaction }),
	object({ TransferObjects: tuple([array(MgoArgument), MgoArgument]) }),
	object({ SplitCoins: tuple([MgoArgument, array(MgoArgument)]) }),
	object({ MergeCoins: tuple([MgoArgument, array(MgoArgument)]) }),
	object({
		Publish: union([
			// TODO: Remove this after 0.34 is released:
			tuple([MgoMovePackage, array(string())]),
			array(string()),
		]),
	}),
	object({
		Upgrade: union([
			// TODO: Remove this after 0.34 is released:
			tuple([MgoMovePackage, array(string()), string(), MgoArgument]),
			tuple([array(string()), string(), MgoArgument]),
		]),
	}),
	object({ MakeMoveVec: tuple([nullable(string()), array(MgoArgument)]) }),
]);

export const MgoCallArg = union([
	object({
		type: literal('pure'),
		valueType: nullable(string()),
		value: MgoJsonValue,
	}),
	object({
		type: literal('object'),
		objectType: literal('immOrOwnedObject'),
		objectId: string(),
		version: string(),
		digest: string(),
	}),
	object({
		type: literal('object'),
		objectType: literal('sharedObject'),
		objectId: string(),
		initialSharedVersion: string(),
		mutable: boolean(),
	}),
]);
export type MgoCallArg = Infer<typeof MgoCallArg>;

export const ProgrammableTransaction = object({
	transactions: array(MgoTransaction),
	inputs: array(MgoCallArg),
});
export type ProgrammableTransaction = Infer<typeof ProgrammableTransaction>;
export type MgoTransaction = Infer<typeof MgoTransaction>;

/**
 * 1. WaitForEffectsCert: waits for TransactionEffectsCert and then returns to the client.
 *    This mode is a proxy for transaction finality.
 * 2. WaitForLocalExecution: waits for TransactionEffectsCert and makes sure the node
 *    executed the transaction locally before returning to the client. The local execution
 *    makes sure this node is aware of this transaction when the client fires subsequent queries.
 *    However, if the node fails to execute the transaction locally in a timely manner,
 *    a bool type in the response is set to false to indicate the case.
 */
export type ExecuteTransactionRequestType = 'WaitForEffectsCert' | 'WaitForLocalExecution';

export type TransactionKindName =
	| 'ChangeEpoch'
	| 'ConsensusCommitPrologue'
	| 'Genesis'
	| 'ProgrammableTransaction';

export const MgoTransactionBlockKind = union([
	assign(MgoChangeEpoch, object({ kind: literal('ChangeEpoch') })),
	assign(
		MgoConsensusCommitPrologue,
		object({
			kind: literal('ConsensusCommitPrologue'),
		}),
	),
	assign(Genesis, object({ kind: literal('Genesis') })),
	assign(ProgrammableTransaction, object({ kind: literal('ProgrammableTransaction') })),
]);
export type MgoTransactionBlockKind = Infer<typeof MgoTransactionBlockKind>;

export const MgoTransactionBlockData = object({
	// Eventually this will become union(literal('v1'), literal('v2'), ...)
	messageVersion: literal('v1'),
	transaction: MgoTransactionBlockKind,
	sender: string(),
	gasData: MgoGasData,
});
export type MgoTransactionBlockData = Infer<typeof MgoTransactionBlockData>;

/** @deprecated Use `string` instead. */
export const AuthoritySignature = string();
export const GenericAuthoritySignature = union([string(), array(string())]);

export const AuthorityQuorumSignInfo = object({
	epoch: string(),
	signature: GenericAuthoritySignature,
	signers_map: array(number()),
});
export type AuthorityQuorumSignInfo = Infer<typeof AuthorityQuorumSignInfo>;

export const GasCostSummary = object({
	computationCost: string(),
	storageCost: string(),
	storageRebate: string(),
	nonRefundableStorageFee: string(),
});
export type GasCostSummary = Infer<typeof GasCostSummary>;

export const ExecutionStatusType = union([literal('success'), literal('failure')]);
export type ExecutionStatusType = Infer<typeof ExecutionStatusType>;

export const ExecutionStatus = object({
	status: ExecutionStatusType,
	error: optional(string()),
});
export type ExecutionStatus = Infer<typeof ExecutionStatus>;

export const OwnedObjectRef = object({
	owner: ObjectOwner,
	reference: MgoObjectRef,
});
export type OwnedObjectRef = Infer<typeof OwnedObjectRef>;
export const TransactionEffectsModifiedAtVersions = object({
	objectId: string(),
	sequenceNumber: string(),
});

export const TransactionEffects = object({
	// Eventually this will become union(literal('v1'), literal('v2'), ...)
	messageVersion: literal('v1'),

	/** The status of the execution */
	status: ExecutionStatus,
	/** The epoch when this transaction was executed */
	executedEpoch: string(),
	/** The version that every modified (mutated or deleted) object had before it was modified by this transaction. **/
	modifiedAtVersions: optional(array(TransactionEffectsModifiedAtVersions)),
	gasUsed: GasCostSummary,
	/** The object references of the shared objects used in this transaction. Empty if no shared objects were used. */
	sharedObjects: optional(array(MgoObjectRef)),
	/** The transaction digest */
	transactionDigest: string(),
	/** ObjectRef and owner of new objects created */
	created: optional(array(OwnedObjectRef)),
	/** ObjectRef and owner of mutated objects, including gas object */
	mutated: optional(array(OwnedObjectRef)),
	/**
	 * ObjectRef and owner of objects that are unwrapped in this transaction.
	 * Unwrapped objects are objects that were wrapped into other objects in the past,
	 * and just got extracted out.
	 */
	unwrapped: optional(array(OwnedObjectRef)),
	/** Object Refs of objects now deleted (the old refs) */
	deleted: optional(array(MgoObjectRef)),
	/** Object Refs of objects now deleted (the old refs) */
	unwrappedThenDeleted: optional(array(MgoObjectRef)),
	/** Object refs of objects now wrapped in other objects */
	wrapped: optional(array(MgoObjectRef)),
	/**
	 * The updated gas object reference. Have a dedicated field for convenient access.
	 * It's also included in mutated.
	 */
	gasObject: OwnedObjectRef,
	/** The events emitted during execution. Note that only successful transactions emit events */
	eventsDigest: nullable(optional(string())),
	/** The set of transaction digests this transaction depends on */
	dependencies: optional(array(string())),
});
export type TransactionEffects = Infer<typeof TransactionEffects>;

export const TransactionEvents = array(MgoEvent);
export type TransactionEvents = Infer<typeof TransactionEvents>;

const ReturnValueType = tuple([array(number()), string()]);
const MutableReferenceOutputType = tuple([MgoArgument, array(number()), string()]);
const ExecutionResultType = object({
	mutableReferenceOutputs: optional(array(MutableReferenceOutputType)),
	returnValues: optional(array(ReturnValueType)),
});

export const DevInspectResults = object({
	effects: TransactionEffects,
	events: TransactionEvents,
	results: optional(array(ExecutionResultType)),
	error: optional(string()),
});
export type DevInspectResults = Infer<typeof DevInspectResults>;

export type MgoTransactionBlockResponseQuery = {
	filter?: TransactionFilter;
	options?: MgoTransactionBlockResponseOptions;
};

export type TransactionFilter =
	| { FromOrToAddress: { addr: string } }
	| { Checkpoint: string }
	| { FromAndToAddress: { from: string; to: string } }
	| { TransactionKind: string }
	| {
			MoveFunction: {
				package: string;
				module: string | null;
				function: string | null;
			};
	  }
	| { InputObject: string }
	| { ChangedObject: string }
	| { FromAddress: string }
	| { ToAddress: string };

export type EmptySignInfo = object;

/** @deprecated Use `string` instead. */
export const AuthorityName = string();
/** @deprecated Use `string` instead. */
export type AuthorityName = Infer<typeof AuthorityName>;

export const MgoTransactionBlock = object({
	data: MgoTransactionBlockData,
	txSignatures: array(string()),
});
export type MgoTransactionBlock = Infer<typeof MgoTransactionBlock>;

export const MgoObjectChangePublished = object({
	type: literal('published'),
	packageId: string(),
	version: string(),
	digest: string(),
	modules: array(string()),
});
export type MgoObjectChangePublished = Infer<typeof MgoObjectChangePublished>;

export const MgoObjectChangeTransferred = object({
	type: literal('transferred'),
	sender: string(),
	recipient: ObjectOwner,
	objectType: string(),
	objectId: string(),
	version: string(),
	digest: string(),
});
export type MgoObjectChangeTransferred = Infer<typeof MgoObjectChangeTransferred>;

export const MgoObjectChangeMutated = object({
	type: literal('mutated'),
	sender: string(),
	owner: ObjectOwner,
	objectType: string(),
	objectId: string(),
	version: string(),
	previousVersion: string(),
	digest: string(),
});
export type MgoObjectChangeMutated = Infer<typeof MgoObjectChangeMutated>;

export const MgoObjectChangeDeleted = object({
	type: literal('deleted'),
	sender: string(),
	objectType: string(),
	objectId: string(),
	version: string(),
});
export type MgoObjectChangeDeleted = Infer<typeof MgoObjectChangeDeleted>;

export const MgoObjectChangeWrapped = object({
	type: literal('wrapped'),
	sender: string(),
	objectType: string(),
	objectId: string(),
	version: string(),
});
export type MgoObjectChangeWrapped = Infer<typeof MgoObjectChangeWrapped>;

export const MgoObjectChangeCreated = object({
	type: literal('created'),
	sender: string(),
	owner: ObjectOwner,
	objectType: string(),
	objectId: string(),
	version: string(),
	digest: string(),
});
export type MgoObjectChangeCreated = Infer<typeof MgoObjectChangeCreated>;

export const MgoObjectChange = union([
	MgoObjectChangePublished,
	MgoObjectChangeTransferred,
	MgoObjectChangeMutated,
	MgoObjectChangeDeleted,
	MgoObjectChangeWrapped,
	MgoObjectChangeCreated,
]);
export type MgoObjectChange = Infer<typeof MgoObjectChange>;

export const BalanceChange = object({
	owner: ObjectOwner,
	coinType: string(),
	/* Coin balance change(positive means receive, negative means send) */
	amount: string(),
});

export const MgoTransactionBlockResponse = object({
	digest: string(),
	transaction: optional(MgoTransactionBlock),
	effects: optional(TransactionEffects),
	events: optional(TransactionEvents),
	timestampMs: optional(string()),
	checkpoint: optional(string()),
	confirmedLocalExecution: optional(boolean()),
	objectChanges: optional(array(MgoObjectChange)),
	balanceChanges: optional(array(BalanceChange)),
	/* Errors that occurred in fetching/serializing the transaction. */
	errors: optional(array(string())),
});
export type MgoTransactionBlockResponse = Infer<typeof MgoTransactionBlockResponse>;

export const MgoTransactionBlockResponseOptions = object({
	/* Whether to show transaction input data. Default to be false. */
	showInput: optional(boolean()),
	/* Whether to show transaction effects. Default to be false. */
	showEffects: optional(boolean()),
	/* Whether to show transaction events. Default to be false. */
	showEvents: optional(boolean()),
	/* Whether to show object changes. Default to be false. */
	showObjectChanges: optional(boolean()),
	/* Whether to show coin balance changes. Default to be false. */
	showBalanceChanges: optional(boolean()),
});

export type MgoTransactionBlockResponseOptions = Infer<typeof MgoTransactionBlockResponseOptions>;

export const PaginatedTransactionResponse = object({
	data: array(MgoTransactionBlockResponse),
	nextCursor: nullable(string()),
	hasNextPage: boolean(),
});
export type PaginatedTransactionResponse = Infer<typeof PaginatedTransactionResponse>;
export const DryRunTransactionBlockResponse = object({
	effects: TransactionEffects,
	events: TransactionEvents,
	objectChanges: array(MgoObjectChange),
	balanceChanges: array(BalanceChange),
	// TODO: Remove optional when this is rolled out to all networks:
	input: optional(MgoTransactionBlockData),
});
export type DryRunTransactionBlockResponse = Infer<typeof DryRunTransactionBlockResponse>;

/* -------------------------------------------------------------------------- */
/*                              Helper functions                              */
/* -------------------------------------------------------------------------- */

export function getTransaction(tx: MgoTransactionBlockResponse): MgoTransactionBlock | undefined {
	return tx.transaction;
}

export function getTransactionDigest(tx: MgoTransactionBlockResponse): string {
	return tx.digest;
}

export function getTransactionSignature(tx: MgoTransactionBlockResponse): string[] | undefined {
	return tx.transaction?.txSignatures;
}

/* ----------------------------- TransactionData ---------------------------- */

export function getTransactionSender(tx: MgoTransactionBlockResponse): string | undefined {
	return tx.transaction?.data.sender;
}

export function getGasData(tx: MgoTransactionBlockResponse): MgoGasData | undefined {
	return tx.transaction?.data.gasData;
}

export function getTransactionGasObject(
	tx: MgoTransactionBlockResponse,
): MgoObjectRef[] | undefined {
	return getGasData(tx)?.payment;
}

export function getTransactionGasPrice(tx: MgoTransactionBlockResponse) {
	return getGasData(tx)?.price;
}

export function getTransactionGasBudget(tx: MgoTransactionBlockResponse) {
	return getGasData(tx)?.budget;
}

export function getChangeEpochTransaction(
	data: MgoTransactionBlockKind,
): MgoChangeEpoch | undefined {
	return data.kind === 'ChangeEpoch' ? data : undefined;
}

export function getConsensusCommitPrologueTransaction(
	data: MgoTransactionBlockKind,
): MgoConsensusCommitPrologue | undefined {
	return data.kind === 'ConsensusCommitPrologue' ? data : undefined;
}

export function getTransactionKind(
	data: MgoTransactionBlockResponse,
): MgoTransactionBlockKind | undefined {
	return data.transaction?.data.transaction;
}

export function getTransactionKindName(data: MgoTransactionBlockKind): TransactionKindName {
	return data.kind;
}

export function getProgrammableTransaction(
	data: MgoTransactionBlockKind,
): ProgrammableTransaction | undefined {
	return data.kind === 'ProgrammableTransaction' ? data : undefined;
}

/* ----------------------------- ExecutionStatus ---------------------------- */

export function getExecutionStatusType(
	data: MgoTransactionBlockResponse,
): ExecutionStatusType | undefined {
	return getExecutionStatus(data)?.status;
}

export function getExecutionStatus(data: MgoTransactionBlockResponse): ExecutionStatus | undefined {
	return getTransactionEffects(data)?.status;
}

export function getExecutionStatusError(data: MgoTransactionBlockResponse): string | undefined {
	return getExecutionStatus(data)?.error;
}

export function getExecutionStatusGasSummary(
	data: MgoTransactionBlockResponse | TransactionEffects,
): GasCostSummary | undefined {
	if (is(data, TransactionEffects)) {
		return data.gasUsed;
	}
	return getTransactionEffects(data)?.gasUsed;
}

export function getTotalGasUsed(
	data: MgoTransactionBlockResponse | TransactionEffects,
): bigint | undefined {
	const gasSummary = getExecutionStatusGasSummary(data);
	return gasSummary
		? BigInt(gasSummary.computationCost) +
				BigInt(gasSummary.storageCost) -
				BigInt(gasSummary.storageRebate)
		: undefined;
}

export function getTotalGasUsedUpperBound(
	data: MgoTransactionBlockResponse | TransactionEffects,
): bigint | undefined {
	const gasSummary = getExecutionStatusGasSummary(data);
	return gasSummary
		? BigInt(gasSummary.computationCost) + BigInt(gasSummary.storageCost)
		: undefined;
}

export function getTransactionEffects(
	data: MgoTransactionBlockResponse,
): TransactionEffects | undefined {
	return data.effects;
}

/* ---------------------------- Transaction Effects --------------------------- */

export function getEvents(data: MgoTransactionBlockResponse): MgoEvent[] | undefined {
	return data.events;
}

export function getCreatedObjects(data: MgoTransactionBlockResponse): OwnedObjectRef[] | undefined {
	return getTransactionEffects(data)?.created;
}

/* --------------------------- TransactionResponse -------------------------- */

export function getTimestampFromTransactionResponse(
	data: MgoTransactionBlockResponse,
): string | undefined {
	return data.timestampMs ?? undefined;
}

/**
 * Get the newly created coin refs after a split.
 */
export function getNewlyCreatedCoinRefsAfterSplit(
	data: MgoTransactionBlockResponse,
): MgoObjectRef[] | undefined {
	return getTransactionEffects(data)?.created?.map((c) => c.reference);
}

export function getObjectChanges(data: MgoTransactionBlockResponse): MgoObjectChange[] | undefined {
	return data.objectChanges;
}

export function getPublishedObjectChanges(
	data: MgoTransactionBlockResponse,
): MgoObjectChangePublished[] {
	return (
		(data.objectChanges?.filter((a) =>
			is(a, MgoObjectChangePublished),
		) as MgoObjectChangePublished[]) ?? []
	);
}
