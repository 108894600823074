import clsx from 'clsx';
import { type ReactNode } from 'react';

export function HighlightedTableCol({ children, first }: { children: ReactNode; first?: boolean }) {
	return (
		<div
			className={clsx(
				'mr-3 flex h-full items-center rounded hover:bg-mgo-light',
				!first && '-ml-3',
			)}
		>
			<div className={clsx(!first && 'ml-3')}>{children}</div>
		</div>
	);
}
