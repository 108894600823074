import { useMgoClient } from '@mgonetwork/dapp-kit';
import { type MgoObjectDataFilter } from '@mgonetwork/mango.js/client';
import { useInfiniteQuery } from '@tanstack/react-query';

const MAX_OBJECTS_PER_REQ = 6;

export function useGetOwnedObjects(
	address?: string | null,
	filter?: MgoObjectDataFilter,
	maxObjectRequests = MAX_OBJECTS_PER_REQ,
) {
	const client = useMgoClient();
	return useInfiniteQuery(
		['get-owned-objects', address, filter, maxObjectRequests],
		({ pageParam }) =>
			client.getOwnedObjects({
				owner: address!,
				filter,
				options: {
					showType: true,
					showContent: true,
					showDisplay: true,
				},
				limit: maxObjectRequests,
				cursor: pageParam,
			}),
		{
			staleTime: 10 * 1000,
			enabled: !!address,
			getNextPageParam: (lastPage) => (lastPage?.hasNextPage ? lastPage.nextCursor : null),
		},
	);
}
