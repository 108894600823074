import { formatAddress, formatDigest } from './format.js';
import {
	isValidMgoAddress,
	isValidMgoObjectId,
	isValidTransactionDigest,
	MGO_ADDRESS_LENGTH,
	normalizeMgoAddress,
	normalizeMgoObjectId,
	normalizeStructTag,
	parseStructTag,
} from './mgo-types';

export { fromB64, toB64, fromHEX, toHEX } from '@mgonetwork/bcs';
export { is, assert } from 'superstruct';

export {
	formatAddress,
	formatDigest,
	isValidMgoAddress,
	isValidMgoObjectId,
	isValidTransactionDigest,
	normalizeStructTag,
	normalizeMgoAddress,
	normalizeMgoObjectId,
	parseStructTag,
	MGO_ADDRESS_LENGTH,
};

export const MGO_DECIMALS = 9;
export const MIST_PER_MGO = BigInt(1000000000);

export const MOVE_STDLIB_ADDRESS = '0x1';
export const MGO_FRAMEWORK_ADDRESS = '0x2';
export const MGO_SYSTEM_ADDRESS = '0x3';
export const MGO_CLOCK_OBJECT_ID = normalizeMgoObjectId('0x6');
export const MGO_SYSTEM_MODULE_NAME = 'mgo_system';
export const MGO_TYPE_ARG = `${MGO_FRAMEWORK_ADDRESS}::mgo::MGO`;
export const MGO_SYSTEM_STATE_OBJECT_ID: string = normalizeMgoObjectId('0x5');
export const MGO_USDT_ADDRESS: string =
	'0xefd048221d577dfb24218d5ffd03de4f364e2d1bed4e030a861a0ec01cb75b1';
