import { useContext } from 'react';

import { MgoClientContext } from '../components/MgoClientProvider';

export function useMgoClientContext() {
	const mgoClient = useContext(MgoClientContext);

	if (!mgoClient) {
		throw new Error(
			'Could not find MgoClientContext. Ensure that you have set up the MgoClientProvider',
		);
	}

	return mgoClient;
}

export function useMgoClient() {
	return useMgoClientContext().client;
}
