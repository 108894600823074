import {
	type MoveCallMgoTransaction,
	type MgoArgument,
	type MgoMovePackage,
} from '@mgonetwork/mango.js/client';
import { Text } from '@mgonetwork/ui';
import { type ReactNode } from 'react';

import { flattenMgoArguments } from './utils';
import { ErrorBoundary } from '~/components/error-boundary/ErrorBoundary';
import { ObjectLink } from '~/ui/InternalLink';

export interface TransactionProps<T> {
	type: string;
	data: T;
}

function TransactionContent({ children }: { children?: ReactNode }) {
	return (
		<Text variant="pBody/normal" color="steel-dark">
			{children}
		</Text>
	);
}

function ArrayArgument({ data }: TransactionProps<(MgoArgument | MgoArgument[])[] | undefined>) {
	return (
		<TransactionContent>
			{data && (
				<span className="break-all">
					<Text variant="pBody/medium" color="defined-label-text">
						({flattenMgoArguments(data)})
					</Text>
				</span>
			)}
		</TransactionContent>
	);
}

function MoveCall({ data }: TransactionProps<MoveCallMgoTransaction>) {
	const {
		module: moduleName,
		package: movePackage,
		function: func,
		arguments: args,
		type_arguments: typeArgs,
	} = data;
	return (
		<TransactionContent>
			<Text variant="pBody/medium" color="defined-label-text">
				(package: <ObjectLink objectId={movePackage} />, module:{' '}
				<ObjectLink objectId={`${movePackage}?module=${moduleName}`} label={`'${moduleName}'`} />,
				function: <span className="break-all text-hero-dark">{func}</span>
				{args && <span className="break-all">, arguments: [{flattenMgoArguments(args!)}]</span>}
				{typeArgs && <span className="break-all">, type_arguments: [{typeArgs.join(', ')}]</span>}
			</Text>
		</TransactionContent>
	);
}

export function Transaction({
	type,
	data,
}: TransactionProps<(MgoArgument | MgoArgument[])[] | MoveCallMgoTransaction | MgoMovePackage>) {
	if (type === 'MoveCall') {
		return (
			<ErrorBoundary>
				<MoveCall type={type} data={data as MoveCallMgoTransaction} />
			</ErrorBoundary>
		);
	}

	return (
		<ErrorBoundary>
			<ArrayArgument
				type={type}
				data={type !== 'Publish' ? (data as (MgoArgument | MgoArgument[])[]) : undefined}
			/>
		</ErrorBoundary>
	);
}
