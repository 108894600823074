import type { Infer } from 'superstruct';
import { any, array, boolean, nullable, object, optional, record, string } from 'superstruct';

import { MgoJsonValue } from './common.js';

export const EventId = object({
	txDigest: string(),
	eventSeq: string(),
});

// event types mirror those in "mgo-json-rpc-types/src/mgo_event.rs"

export const MgoEvent = object({
	id: EventId,
	// Move package where this event was emitted.
	packageId: string(),
	// Move module where this event was emitted.
	transactionModule: string(),
	// Sender's Mgo address.
	sender: string(),
	// Move event type.
	type: string(),
	// Parsed json value of the event
	parsedJson: optional(record(string(), any())),
	// Base 58 encoded bcs bytes of the move event
	bcs: optional(string()),
	timestampMs: optional(string()),
});

export type MgoEvent = Infer<typeof MgoEvent>;

export type MoveEventField = {
	path: string;
	value: MgoJsonValue;
};

/**
 * Sequential event ID, ie (transaction seq number, event seq number).
 * 1) Serves as a unique event ID for each fullnode
 * 2) Also serves to sequence events for the purposes of pagination and querying.
 *    A higher id is an event seen later by that fullnode.
 * This ID is the "cursor" for event querying.
 */
export type EventId = Infer<typeof EventId>;

// mirrors mgo_json_rpc_types::MgoEventFilter
export type MgoEventFilter =
	| { Package: string }
	| { MoveModule: { package: string; module: string } }
	| { MoveEventType: string }
	| { MoveEventField: MoveEventField }
	| { Transaction: string }
	| {
			TimeRange: {
				// left endpoint of time interval, milliseconds since epoch, inclusive
				startTime: string;
				// right endpoint of time interval, milliseconds since epoch, exclusive
				endTime: string;
			};
	  }
	| { Sender: string }
	| { All: MgoEventFilter[] }
	| { Any: MgoEventFilter[] }
	| { And: [MgoEventFilter, MgoEventFilter] }
	| { Or: [MgoEventFilter, MgoEventFilter] };

export const PaginatedEvents = object({
	data: array(MgoEvent),
	nextCursor: nullable(EventId),
	hasNextPage: boolean(),
});
export type PaginatedEvents = Infer<typeof PaginatedEvents>;

/* ------------------------------- EventData ------------------------------ */

export function getEventSender(event: MgoEvent): string {
	return event.sender;
}

export function getEventPackage(event: MgoEvent): string {
	return event.packageId;
}
