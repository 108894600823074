import { cva, type VariantProps } from 'class-variance-authority';
import { type ReactNode } from 'react';

import { parseVariant, type SizeAndWeightVariant } from './utils/sizeAndWeight';

const textStyles = cva(['break-words'], {
	variants: {
		size: {
			body: 'text-body',
			bodySmall: 'text-bodySmall',
			subtitle: 'text-subtitle',
			subtitleSmall: 'text-subtitleSmall',
			subtitleSmallExtra: 'text-subtitleSmallExtra',
			caption: 'uppercase text-caption',
			captionSmall: 'uppercase text-captionSmall',
			pBody: 'text-pBody',
			pBodySmall: 'text-pBodySmall',
			pSubtitle: 'text-pSubtitle',
			pSubtitleSmall: 'text-pSubtitleSmall',
		},
		weight: {
			medium: 'font-medium',
			normal: 'font-normal',
			semibold: 'font-semibold',
			bold: 'font-bold',
		},
		color: {
			mgo: 'text-mgo',
			hero: 'text-hero',
			steel: 'text-steel',
			issue: 'text-issue',
			white: 'text-white',
			'gray-100': 'text-gray-100',
			'gray-90': 'text-gray-90',
			'gray-80': 'text-gray-80',
			'gray-75': 'text-gray-75',
			'gray-70': 'text-gray-70',
			'gray-65': 'text-gray-65',
			'gray-60': 'text-gray-60',
			'text-44': 'defined-text-44',
			'mgo-dark': 'text-mgo-dark',
			'hero-dark': 'text-hero-dark',
			'mgo-light': 'text-mgo-light',
			"text-gr-dark": 'text-gr-dark',
			'issue-dark': 'text-issue-dark',
			'steel-dark': 'text-steel-dark',
			'text-success': 'text-success',
			'text-hero-dark': 'text-hero-dark',
			'hero-darkest': 'text-hero-darkest',
			'success-dark': 'text-success-dark',
			'steel-darker': 'text-steel-darker',
			'defined-node-text': 'defined-node-text',
			'defined-node-label': 'defined-node-label',
			
		},
		uppercase: { true: 'uppercase' },
		italic: {
			true: 'italic',
			false: '',
		},
		mono: {
			true: 'font-mono',
			false: 'font-sans',
		},
		truncate: {
			true: 'truncate',
		},
	},
});

type TextStylesProps = VariantProps<typeof textStyles>;

export interface TextProps extends Omit<TextStylesProps, 'size' | 'weight'> {
	variant: SizeAndWeightVariant<TextStylesProps>;
	children: ReactNode;
}

export function Text({ children, variant = 'body/medium', ...styleProps }: TextProps) {
	const [size, weight] = parseVariant<TextStylesProps>(variant);

	return <div className={textStyles({ size, weight, ...styleProps })}>{children}</div>;
}
