import type { MgoClient } from '@mgonetwork/mango.js/client';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { useMgoClientContext } from './useMgoClient';

export type MgoRpcMethodName = {
	[K in keyof MgoClient]: MgoClient[K] extends ((input: any) => Promise<any>) | (() => Promise<any>)
		? K
		: never;
}[keyof MgoClient];

export type MgoRpcMethods = {
	[K in MgoRpcMethodName]: MgoClient[K] extends (input: infer P) => Promise<infer R>
		? {
				name: K;
				result: R;
				params: P;
		  }
		: MgoClient[K] extends () => Promise<infer R>
		? {
				name: K;
				result: R;
				params: undefined;
		  }
		: never;
};

export type UseMgoClientQueryOptions<T extends keyof MgoRpcMethods> = Omit<
	UseQueryOptions<MgoRpcMethods[T]['result'], Error, MgoRpcMethods[T]['result'], unknown[]>,
	'queryFn'
>;

export function useMgoClientQuery<T extends keyof MgoRpcMethods>(
	{
		method,
		params,
	}: {
		method: T;
		params: MgoRpcMethods[T]['params'];
	},
	{ queryKey, enabled = !!params, ...options }: UseMgoClientQueryOptions<T> = {},
) {
	const mgoContext = useMgoClientContext();

	return useQuery({
		...options,
		queryKey: [mgoContext.network, method, params],
		enabled,
		queryFn: async () => {
			return await mgoContext.client[method](params as never);
		},
	});
}
