import { useMgoClient } from '@mgonetwork/dapp-kit';
import { MgoObjectDataOptions } from '@mgonetwork/mango.js/src/client';
import { useQuery } from '@tanstack/react-query';

import { chunkArray } from '../utils/chunkArray';

export function useMultiGetObjects(
	ids: string[],
	options: MgoObjectDataOptions,
	queryOptions?: { keepPreviousData?: boolean },
) {
	const client = useMgoClient();
	return useQuery({
		queryKey: ['multiGetObjects', ids],
		queryFn: async () => {
			const responses = await Promise.all(
				chunkArray(ids, 50).map((chunk) =>
					client.multiGetObjects({
						ids: chunk,
						options,
					}),
				),
			);
			return responses.flat();
		},
		enabled: !!ids?.length,
		...queryOptions,
	});
}
