import type { UseMgoClientQueryOptions } from './useMgoClientQuery';
import { useMgoClientQuery } from './useMgoClientQuery';

export function useRpcApiVersion(options?: UseMgoClientQueryOptions<'getRpcApiVersion'>) {
	return useMgoClientQuery(
		{
			method: 'getRpcApiVersion',
			params: {},
		},
		options,
	);
}
