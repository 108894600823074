import { type SharedObjectRef } from '@mgonetwork/mango.js/bcs';
import { type MgoClient, type MgoObjectRef } from '@mgonetwork/mango.js/client';
import { type TransactionArgument } from '@mgonetwork/mango.js/transactions';

import { BaseRulePackageIds } from '../constants';

export * from './kiosk';
export * from './transfer-policy';

/**
 * A valid argument for any of the Kiosk functions.
 */
export type ObjectArgument = string | TransactionArgument | SharedObjectRef | MgoObjectRef;

/**
 * A Network selector.
 * Kiosk SDK supports mainnet & testnet.
 * Pass `custom` for any other network (devnet, localnet).
 */
export enum Network {
	MAINNET = 'mainnet',
	TESTNET = 'testnet',
	CUSTOM = 'custom',
}

/**
 * The Client Options for Both KioskClient & TransferPolicyManager.
 */
export type KioskClientOptions = {
	client: MgoClient;
	network: Network;
	packageIds?: BaseRulePackageIds;
};
