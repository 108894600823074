import { useTransactionSummary } from '@mgonetwork/core';
import {
	type ProgrammableTransaction,
	type MgoTransactionBlockResponse,
} from '@mgonetwork/mango.js/client';

import { GasBreakdown } from '~/components/GasBreakdown';
import { useRecognizedPackages } from '~/hooks/useRecognizedPackages';
import { InputsCard } from '~/pages/transaction-result/programmable-transaction-view/InputsCard';
import { TransactionsCard } from '~/pages/transaction-result/programmable-transaction-view/TransactionsCard';

interface Props {
	transaction: MgoTransactionBlockResponse;
}

export function TransactionData({ transaction }: Props) {
	const recognizedPackagesList = useRecognizedPackages();
	const summary = useTransactionSummary({
		transaction,
		recognizedPackagesList,
	});

	const transactionKindName = transaction.transaction?.data.transaction.kind;

	const isProgrammableTransaction = transactionKindName === 'ProgrammableTransaction';

	const programmableTxn = transaction.transaction!.data.transaction as ProgrammableTransaction;

	return (
		<div className="flex flex-wrap gap-3 md:gap-6">
			<section className="flex w-96 flex-1 flex-col gap-3 md:min-w-transactionColumn md:gap-6">
				{isProgrammableTransaction && (
					<div data-testid="inputs-card " className="relative w-full">
						<InputsCard inputs={programmableTxn.inputs} />
					</div>
				)}
				{isProgrammableTransaction && (
					<>
						<div data-testid="transactions-card">
							<TransactionsCard transactions={programmableTxn.transactions} />
						</div>
						<div data-testid="gas-breakdown">
							<GasBreakdown summary={summary} />
						</div>
					</>
				)}
			</section>
		</div>
	);
}
