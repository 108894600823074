import { useFeatureIsOn } from '@growthbook/growthbook-react';
import {
	// useAppsBackend,
	useElementDimensions,
} from '@mgonetwork/core';
import { LoadingIndicator } from '@mgonetwork/ui';
// import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { type ReactNode, useRef } from 'react';

import Footer from '../footer/Footer';
import Header from '../header/Header';
import { useNetworkContext } from '~/context';
import { Banner } from '~/ui/Banner';
import { Network } from '~/utils/api/DefaultRpcClient';

import styles from './pageLayout.module.css';

export type PageLayoutProps = {
	gradient?: {
		content: ReactNode;
		size: 'lg' | 'md';
	};
	isError?: boolean;
	content: ReactNode;
	loading?: boolean;
	isRemovePadding?: boolean;
};

const DEFAULT_HEADER_HEIGHT = 68;
export function PageLayout({
	gradient,
	content,
	loading,
	isError,
	isRemovePadding = false,
}: PageLayoutProps) {
	const [network] = useNetworkContext();
	// const { request } = useAppsBackend();
	const outageOverride = useFeatureIsOn('network-outage-override');

	// const { data } = useQuery({
	// 	queryKey: ['apps-backend', 'monitor-network'],
	// 	queryFn: () =>
	// 		request<{ degraded: boolean }>('monitor-network', {
	// 			project: 'EXPLORER',
	// 		}),
	// 	// Keep cached for 2 minutes:
	// 	staleTime: 2 * 60 * 1000,
	// 	retry: false,
	// 	enabled: network === Network.DEVNET,
	// });
	const data = { degraded: false };

	const isGradientVisible = !!gradient;
	const renderNetworkDegradeBanner =
		outageOverride || (network === Network.DEVNET && data?.degraded);
	const headerRef = useRef<HTMLElement | null>(null);
	const [headerHeight] = useElementDimensions(headerRef, DEFAULT_HEADER_HEIGHT);

	return (
		<div className="defined-default-bg relative min-h-screen w-full">
			<section ref={headerRef} className="fixed top-0 z-20 flex w-full flex-col">
				{renderNetworkDegradeBanner && (
					<Banner rounded="none" align="center" variant="warning" fullWidth>
						<div className="break-normal">
							The explorer is running slower than usual. We&rsquo;re working to fix the issue and
							appreciate your patience.
						</div>
					</Banner>
				)}
				<Header />
			</section>
			{loading && (
				<div className="absolute left-1/2 right-0 top-1/2 flex -translate-x-1/2 -translate-y-1/2 transform justify-center">
					<LoadingIndicator variant="lg" />
				</div>
			)}
			<main
				className={clsx('relative z-10 ', styles.bgOffWhiteDefined)}
				style={
					!isGradientVisible
						? {
								paddingTop: `${headerHeight}px`,
						  }
						: {}
				}
			>
				{isGradientVisible ? (
					<section
						style={{
							paddingTop: `${headerHeight}px`,
						}}
						className={clsx(
							'group/gradientContent',
							loading && styles.bgGradientsGraphCardsDefined,
							isError && 'bg-gradients-failure',
							!isError && styles.bgGradientsGraphCardsDefined,
						)}
					>
						<div
							className={clsx(
								'mx-auto max-w-[1440px] py-8 lg:px-6 xl:px-10',
								gradient.size === 'lg' && 'px-4 xl:py-12',
								gradient.size === 'md' && 'px-4',
							)}
						>
							{gradient.content}
						</div>
					</section>
				) : null}
				{!loading && (
					<section
						className={clsx(
							'mx-auto max-w-[1440px] ',
							!isRemovePadding ? 'p-5 pb-20 sm:py-8 md:p-10 md:pb-20' : '',
						)}
					>
						{content}
					</section>
				)}
			</main>
			<Footer />
		</div>
	);
}
