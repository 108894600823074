import { useMgoClient } from '@mgonetwork/dapp-kit';
import { MgoClient } from '@mgonetwork/mango.js/client';
import { useMemo } from 'react';

import { useNetwork } from '~/context';
import { Network } from '~/utils/api/DefaultRpcClient';

// TODO: Use enhanced RPC locally by default
export function useEnhancedRpcClient() {
	const [network] = useNetwork();
	const client = useMgoClient();
	const enhancedRpc = useMemo(() => {
		if (network === Network.LOCAL) {
			return new MgoClient({ url: 'http://localhost:9124' });
		}

		return client;
	}, [network, client]);

	return enhancedRpc;
}
