/**
 *  ######################################
 *  ### DO NOT EDIT THIS FILE DIRECTLY ###
 *  ######################################
 *
 * This file is generated from:
 * /crates/mgo-open-rpc/spec/openrpc.json
 */

import type { GetDynamicFieldObjectParams } from '@mgonetwork/mango.js/client';

import type { UseMgoClientQueryOptions } from '../useMgoClientQuery';
import { useMgoClientQuery } from '../useMgoClientQuery';

export function useDynamicFieldObject(
	params: GetDynamicFieldObjectParams,
	options?: UseMgoClientQueryOptions<'getDynamicFieldObject'>,
) {
	return useMgoClientQuery(
		{
			method: 'getDynamicFieldObject',
			params,
		},
		options,
	);
}
