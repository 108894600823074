import { useMemo } from 'react';

import type { MgoMoveAbilitySet } from '@mgonetwork/mango.js/client';

export function useFunctionTypeArguments(typeArguments: MgoMoveAbilitySet[]) {
	return useMemo(
		() =>
			typeArguments.map(
				(aTypeArgument, index) =>
					`T${index}${
						aTypeArgument.abilities.length ? `: ${aTypeArgument.abilities.join(' + ')}` : ''
					}`,
			),
		[typeArguments],
	);
}
