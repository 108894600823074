import { getFullnodeUrl, isMgoClient, MgoClient } from '@mgonetwork/mango.js/client';
import type { MgoClientOptions } from '@mgonetwork/mango.js/client';
import { createContext, useMemo, useState } from 'react';

type NetworkConfig = MgoClient | MgoClientOptions;
type NetworkConfigs<T extends NetworkConfig = NetworkConfig> = Record<string, T>;

export interface MgoClientProviderContext {
	client: MgoClient;
	networks: NetworkConfigs;
	network: string;
	selectNetwork: (network: string) => void;
}

export const MgoClientContext = createContext<MgoClientProviderContext | null>(null);

export type MgoClientProviderProps<T extends NetworkConfigs> = {
	createClient?: (name: keyof T, config: T[keyof T]) => MgoClient;
	children: React.ReactNode;
	networks?: T;
	onNetworkChange?: (network: keyof T & string) => void;
} & (
	| {
			defaultNetwork?: keyof T & string;
			network?: never;
	  }
	| {
			defaultNetwork?: never;
			network?: keyof T & string;
	  }
);

const DEFAULT_NETWORKS = {
	localnet: { url: getFullnodeUrl('localnet') },
};

const DEFAULT_CREATE_CLIENT = function createClient(
	_name: string,
	config: NetworkConfig | MgoClient,
) {
	if (isMgoClient(config)) {
		return config;
	}

	return new MgoClient(config);
};

export function MgoClientProvider<T extends NetworkConfigs>(props: MgoClientProviderProps<T>) {
	const { onNetworkChange, network, children } = props;
	const networks = (props.networks ?? DEFAULT_NETWORKS) as T;
	const createClient =
		(props.createClient as typeof DEFAULT_CREATE_CLIENT) ?? DEFAULT_CREATE_CLIENT;

	const [selectedNetwork, setSelectedNetwork] = useState<keyof T & string>(
		props.network ?? props.defaultNetwork ?? (Object.keys(networks)[0] as keyof T & string),
	);

	const currentNetwork = props.network ?? selectedNetwork;

	const client = useMemo(() => {
		return createClient(currentNetwork, networks[currentNetwork]);
	}, [createClient, currentNetwork, networks]);

	const ctx = useMemo((): MgoClientProviderContext => {
		return {
			client,
			networks,
			network: currentNetwork,
			selectNetwork: (newNetwork) => {
				if (currentNetwork === newNetwork) {
					return;
				}

				if (!network && newNetwork !== selectedNetwork) {
					setSelectedNetwork(newNetwork);
				}

				onNetworkChange?.(newNetwork);
			},
		};
	}, [client, networks, selectedNetwork, currentNetwork, network, onNetworkChange]);

	return <MgoClientContext.Provider value={ctx}>{children}</MgoClientContext.Provider>;
}
