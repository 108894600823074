import { useFormatCoin, formatBalance, CoinFormat } from '@mgonetwork/core';
import { MGO_TYPE_ARG } from '@mgonetwork/mango.js/utils';
import { Heading, Text } from '@mgonetwork/ui';

type DelegationAmountProps = {
	amount: bigint | number | string;
	isStats?: boolean;
	inMIST?: boolean;
};

export function DelegationAmount({ amount, isStats, inMIST = false }: DelegationAmountProps) {
	const [formattedAmount, symbol] = useFormatCoin(amount, MGO_TYPE_ARG);
	const delegationAmount = inMIST ? formatBalance(amount, 0, CoinFormat.FULL) : formattedAmount;
	const delegationSymbol = inMIST ? 'MANGO' : symbol;
	return isStats ? (
		<div className="flex items-end gap-1.5 break-all">
			<Heading as="div" variant="heading3/normal" color="white">
				{delegationAmount}
			</Heading>
			<Heading variant="heading4/normal" color="white">
				{delegationSymbol}
			</Heading>
		</div>
	) : (
		<div className="flex h-full items-center gap-1">
			<div className="defined-text-white flex items-baseline gap-0.5 break-all">
				<Text variant="body/normal">{delegationAmount}</Text>
				<Text variant="subtitleSmall/normal">{delegationSymbol}</Text>
			</div>
		</div>
	);
}
