/**
 *  ######################################
 *  ### DO NOT EDIT THIS FILE DIRECTLY ###
 *  ######################################
 *
 * This file is generated from:
 * /crates/mgo-open-rpc/spec/openrpc.json
 */

import type { GetLatestMgoSystemStateParams } from '@mgonetwork/mango.js/client';

import type { UseMgoClientQueryOptions } from '../useMgoClientQuery';
import { useMgoClientQuery } from '../useMgoClientQuery';

export function useLatestMgoSystemState(
	params: GetLatestMgoSystemStateParams = {},
	options?: UseMgoClientQueryOptions<'getLatestMgoSystemState'>,
) {
	return useMgoClientQuery(
		{
			method: 'getLatestMgoSystemState',
			params,
		},
		options,
	);
}
