import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useMgoClient } from '@mgonetwork/dapp-kit';
import { useQuery } from '@tanstack/react-query';

const MGO_NS_FEATURE_FLAG = 'mgons';

// This should align with whatever names we want to be able to resolve.
const MGO_NS_DOMAINS = ['.mgo'];
export function isMgoNSName(name: string) {
	return MGO_NS_DOMAINS.some((domain) => name.endsWith(domain));
}

export function useMgoNSEnabled() {
	return useFeatureIsOn(MGO_NS_FEATURE_FLAG);
}

export function useResolveMgoNSAddress(name?: string | null) {
	const client = useMgoClient();
	const enabled = useMgoNSEnabled();

	return useQuery({
		queryKey: ['resolve-mgons-address', name],
		queryFn: async () => {
			return await client.resolveNameServiceAddress({
				name: name!,
			});
		},
		enabled: !!name && enabled,
		refetchOnWindowFocus: false,
		retry: false,
	});
}

export function useResolveMgoNSName(address?: string | null) {
	const client = useMgoClient();
	const enabled = useMgoNSEnabled();

	return useQuery({
		queryKey: ['resolve-mgons-name', address],
		queryFn: async () => {
			// NOTE: We only fetch 1 here because it's the default name.
			const { data } = await client.resolveNameServiceNames({
				address: address!,
				limit: 1,
			});

			return data[0] || null;
		},
		enabled: !!address && enabled,
		refetchOnWindowFocus: false,
		retry: false,
	});
}
