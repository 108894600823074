import clsx from 'clsx';
import { useEffect, useState } from 'react';

import { ReactComponent as DefaultLogo } from '../../assets/defaultLogo.svg';
import NetworkSelect from '../network/Network';
import Search from '../search/Search';
// import ThemeSelect from '~/components/theme/ThemeSelect';
import { LinkWithQuery } from '~/ui/utils/LinkWithQuery';

import styles from '../HomeMetrics/homeMetrics.module.css';
function Header() {
	const [isScrolled, setIsScrolled] = useState(window.scrollY > 0);
	useEffect(() => {
		const callback = () => {
			setIsScrolled(window.scrollY > 0);
		};
		document.addEventListener('scroll', callback, { passive: true });
		return () => {
			document.removeEventListener('scroll', callback);
		};
	}, []);

	return (
		<header
			className={clsx(
				'flex h-header justify-center overflow-visible backdrop-blur-xl transition-shadow',
				isScrolled && 'shadow-mistyEdge',
				styles['definedBlack/40'],
			)}
		>
			<div className="flex h-full max-w-[1440px] flex-1 items-center gap-5 px-5 2xl:p-0">
				<div className="flex w-full gap-2 md:gap-10">
					{/*@ts-ignore*/}
					<LinkWithQuery className="default-logo-tag-a" to="/">
						<DefaultLogo className="default-logo-size" />
					</LinkWithQuery>
					<div className="flex-1">
						<Search />
					</div>
					<NetworkSelect />
					{/*<ThemeSelect />*/}
				</div>
			</div>
		</header>
	);
}

export default Header;
