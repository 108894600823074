import { SVGProps } from 'react';

const SvgMgo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" version="1.1" width="28" height="28" viewBox="0 0 24 24">
    <g>
      <ellipse cx="12" cy="12" rx="12" ry="12" fill="#000000" />
    </g>
    <g>
      <path d="M7,19L9.8506,16.9L9.85061,11.43125L12.15625,12.9625L12.15625,8.7625L7,5L7,19ZM18,19L15.1494,16.9L15.14939,11.43125L12.84375,12.9625L12.84375,8.7625L18,5L18,19Z" fillRule="evenodd" fill="#81D8CF"/>
    </g>
  </svg>
);

export default SvgMgo;
