import { Button } from '@mgonetwork/ui';
import { ConnectButton, useWalletKit, type WalletWithFeatures } from '@mgonetwork/wallet-kit';
import { useParams } from 'react-router-dom';

import { useTheme } from '~/context';

// This is a custom feature supported by the Mgo Wallet:
type StakeInput = { validatorAddress: string };
type MgoWalletStakeFeature = {
	'mgoWallet:stake': {
		version: '0.0.1';
		stake: (input: StakeInput) => Promise<void>;
	};
};

type StakeWallet = WalletWithFeatures<Partial<MgoWalletStakeFeature>>;

export function StakeButton() {
	const [localTheme] = useTheme();
	// const stakeButtonEnabled = useFeatureIsOn('validator-page-staking');
	const { id } = useParams();
	const { wallets, currentWallet, connect } = useWalletKit();
	// if (!stakeButtonEnabled) return null;
	// @ts-ignore
	const stakeSupportedWallets = wallets.filter((wallet) => 'mgoWallet:stake' in wallet.features);
	const currentWalletSupportsStake =
		// @ts-ignore
		currentWallet && !!stakeSupportedWallets.find(({ name }) => currentWallet.name === name);
	if (!stakeSupportedWallets.length) {
		return (
			<Button size="lg" variant="definedBtn" asChild attr-id="defined-stake-btn">
				<a href="" target="_blank" rel="noreferrer noopener">
					Install Mgo Wallet to stake Mgo
				</a>
			</Button>
		);
	}

	if (!currentWallet) {
		return (
			<ConnectButton
				attr-id="defined-stake-btn"
				theme={localTheme}
				className="!border !border-solid !border-steel-dark !bg-transparent !px-4 !py-3 !text-body !font-semibold !text-steel-dark !shadow-none"
				connectText="Stake Mgo"
			/>
		);
	}

	if (!currentWalletSupportsStake) {
		return (
			<Button
				attr-id="defined-stake-btn"
				variant="stakeStyle"
				size="lg"
				onClick={() => {
					// Always just assume we should connect to the first stake supported wallet for now:
					connect(stakeSupportedWallets[0].name);
				}}
			>
				Stake Mgo on a supported wallet
			</Button>
		);
	}

	return (
		<Button
			size="lg"
			attr-id="defined-stake-btn"
			variant="stakeStyle"
			onClick={() => {
				(currentWallet as StakeWallet).features['mgoWallet:stake']?.stake({
					validatorAddress: id!,
				});
			}}
		>
			Stake Mgo
		</Button>
	);
}
