/** Mgo Devnet */
export const MGO_DEVNET_CHAIN = 'mgo:devnet';

/** Mgo Testnet */
export const MGO_TESTNET_CHAIN = 'mgo:testnet';

/** Mgo Localnet */
export const MGO_LOCALNET_CHAIN = 'mgo:localnet';

/** Mgo Mainnet */
export const MGO_MAINNET_CHAIN = 'mgo:mainnet';

export const MGO_CHAINS = [
	MGO_DEVNET_CHAIN,
	MGO_TESTNET_CHAIN,
	MGO_LOCALNET_CHAIN,
	MGO_MAINNET_CHAIN,
] as const;

export type MgoChain =
	| typeof MGO_DEVNET_CHAIN
	| typeof MGO_TESTNET_CHAIN
	| typeof MGO_LOCALNET_CHAIN
	| typeof MGO_MAINNET_CHAIN;
